export enum ReasonState {
  afterMaturityDate = 'afterMaturityDate',
  soldOut = 'soldOut',
  unavailable = 'unavailable',
  unknown = 'unknown',
  availableForBay = 'availableForBay',
}

export const DMAT_CHECKS_COUNT = 5

export const ISSUE_URL_OLD = 'issues'
export const ISSUE_URL = 'dluhopisy'

export const UNKNOWN_BUSINESS_CATEGORY_ID = -1
