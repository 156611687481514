import type { PaginationByOffset, PaginationByPage } from '@nuxtjs/strapi'
import type { DeepReadonly } from 'vue'
import type { MailisearchIssue } from '~/api/types'
import type { IssuesListFilters } from '~/stores/issues/types'
import type { IssueSortTypes } from '~/composables/issues/useIssuesList/types'

export enum IssuesRisks {
  Medium = 'Střední riziko',
  High = 'Vyšší riziko',
  Highest = 'Vysoké riziko',
  Low = 'Nízké riziko',
  Lowest = 'Nejnižší riziko',
}

export enum CompanyBusinessTypes {
  Agriculture = 'A',
  Mining = 'B',
  Manufacturing = 'C',
  EGH = 'D',
  WaterSupply = 'E',
  ConstructionIndustry = 'F',
  WholesaleRetail = 'G',
  Transport = 'H',
  Accommodation = 'I',
  IT = 'J',
  FinanceInsurance = 'K',
  RealEstate = 'L',
  Professional = 'M',
  Administration = 'N',
  PublicAdministration = 'O',
  Education = 'P',
  HealthCare = 'Q',
  Entertainment = 'R',
  Other = 'S',
  Households = 'T',
  Extraterritorial = 'U',
}

export enum IssuesReturnPayoutProbabilities {
  MediumHigh = 'Středně vysoká',
  Low = 'Nízká',
  VeryLow = 'Velmi nízká',
  QuiteHigh = 'Poměrně vysoká',
  VeryHigh = 'Velmi vysoká',
}

export enum IssuesScoring {
  NotCovered = 'NotCovered',
  HighReturn = 'HighReturn',
  Balanced = 'Balanced',
  HighCover = 'HighCover',
  Covered = 'Covered',
}

export enum IssuesMaturities {
  OneToTwo = 'oneToTwo',
  TwoToFour = 'twoToFour',
  FourToSix = 'fourToSix',
}

export enum IssuesReturnsPeriodicities {
  Quarterly = 'Čtvrtletně',
  Monthly = 'Měsíčně',
  HalfYearly = 'Půlročně',
  Yearly = 'Ročně',
  OneTime = 'Jednorázově',
}

export enum BondIssueType {
  Company = 'Podnikový dluhopis',
  State = 'Státní dluhopis',
}

export enum YieldType {
  Solid = 'S pevným výnosem',
  Variable = 'S variabilním výnosem',
  Zero = 'Zero bond',
}

export enum IssuesCollateralType {
  hedgingAgent = 'Agent pro zajištění',
  movables = 'Movitosti',
  properties = 'Nemovitosti',
  ownerGuaranteeStatement = 'Ručitelské prohlášení majitele',
  guaranteeDeclarationOfLegalEntity = 'Ručitelské prohlášení právnické osoby',
  notarialDeed = 'Notářský zápis',
  none = 'Žádná',
}

export enum IssueCollateralTextVariablesType {
  guaranteeDeclarationOfLegalEntity = 'Ručitelské prohlášení právnické osoby',
  ownerGuaranteeStatement = 'Ručitelské prohlášení majitele',
  hedgingAgent = 'Agent pro zajištění',
}

export const collateralTypesToDmatChecks = [
  [IssuesCollateralType.hedgingAgent],
  [IssuesCollateralType.notarialDeed],
  [IssuesCollateralType.properties, IssuesCollateralType.movables],
  [IssuesCollateralType.guaranteeDeclarationOfLegalEntity],
  [IssuesCollateralType.ownerGuaranteeStatement],
]

export enum IssuesInvestorType {
  Balanced = 'Balancovaný',
  Dynamic = 'Dynamický',
  Aggresive = 'Agresivní',
  Conservative = 'Konzervativní',
  Defensive = 'Defenzivní',
}

export enum ShortIssueKeys {
  isin = 'isin',
  name = 'name',
  slug = 'slug',
  nominalValue = 'nominalValue',
  hasEarlyRepayment = 'hasEarlyRepayment',
  maturityDate = 'maturityDate',
  issueDate = 'issueDate',
  payoutDate = 'payoutDate',
  affiliateURL = 'affiliateURL',
  issueURL = 'issueURL',
  type = 'type',
  currency = 'currency',
  jamesOrderFormURL = 'jamesOrderFormURL',
  top = 'top',
  availableCount = 'availableCount',
  totalAmount = 'totalAmount',
  isAvailable = 'isAvailable',
  dmatChecks = 'dmatChecks',
  returnsPeriodicity = 'returnsPeriodicity',
}

export enum ShortIssuesPopulateKeys {
  company = 'company',
  collaterals = 'collaterals',
  interestRate = 'interestRate',
  scoreData = 'scoreData',
}

export enum ShortIssuesPopulateKeysDeep {
  companyLustration = 'company.lustration',
  companyLustrationInsolvency = 'company.lustration.insolvency',
  companyFiles = 'collaterals.files',
  companyBusinessType = 'company.businessType',
}

export enum IssueSortKeys {
  dmatChecks = 'dmatChecks',
  annualInterestRate = 'interestRate.annualInterestRate',
  mfCrScore = 'scoreData.score',
}

export const CzNameTypesTranslation = {
  a: 'Zemědělství, lesnictví, rybářství',
  b: 'Těžba a dobývání',
  c: 'Zpracovatelský průmysl',
  d: 'Výroba a rozvod elektřiny, plynu, tepla a klimatiz. vzduchu',
  e: 'Zásobování vodou; činnosti související s odpady a sanacemi',
  f: 'Stavebnictví',
  g: 'Velkoobchod a maloobchod; opravy a údržba motorových vozidel',
  h: 'Doprava a skladování',
  i: 'Ubytování, stravování a pohostinství',
  j: 'Informační a komunikační činnosti',
  k: 'Peněžnictví a pojišťovnictví',
  l: 'Činnosti v oblasti nemovitostí',
  m: 'Profesní, vědecké a technické činnosti',
  n: 'Administrativní a podpůrné činnosti',
  o: 'Veřejná správa a obrana; povinné sociální zabezpečení',
  p: 'Vzdělávání',
  q: 'Zdravotní a sociální péče',
  r: 'Kulturní, zábavní a rekreační činnosti',
  s: 'Ostatní činnosti',
  t: 'Činnosti domácností',
  u: 'Činnosti exteritoriálních organizací a orgánů',
}

export type getIssuesListServiceInput = {
  filters: DeepReadonly<Partial<IssuesListFilters>>
  pagination?: PaginationByPage | PaginationByOffset
  search?: string
  sort?: {
    key: IssueSortTypes
    direction: 'asc' | 'desc'
  }
  includeDefaultSort?: boolean
}

export type ShortIssue = Pick<MailisearchIssue, ShortIssueKeys | ShortIssuesPopulateKeys>
export type ShortIssueWithId = ShortIssue & { id: number }
